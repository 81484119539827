<template>
    <div class="configurator taylor-you-configurator">
        <div class="preview-images d-flex justify-center align-center">
            <img
                @load="imageLoaded"
                :src="`https://t6q7c3m8.stackpathcdn.com/zbuilder/necklace/TYPF1011/TY1011-${config.letterType}-${config.metalColor}-${config.letter}-V1.png`">
            <loader v-if="imageLoader"/>
        </div>
        <div class="preview-options d-flex">
            <letter-type @onChange="value => updateConfig('letterType', value)"/>
            <letter
                :config="config"
                @onChange="value => updateConfig('letter', value)"/>
            <metal-color
                :builder="'taylorYou'"
                @onChange="value => updateConfig('metalColor', value)"/>
        </div>
    </div>
</template>

<script>
    import MetalColor from "@/components/ui/configuratorElements/MetalColor";
    import LetterType from "@/components/ui/configuratorElements/LetterType";
    import Letter from "@/components/ui/configuratorElements/Letter";
    import Loader from "@/components/ui/Loader";

    export default {
        name: "taylor-you-configurator",
        components: {
            MetalColor,
            LetterType,
            Letter,
            Loader
        },
        data() {
            return {
                imageLoader: true,
                config: {
                    letterType: "UC",
                    letter: "A",
                    metalColor: "WG"
                }
            }
        },
        methods: {
            updateConfig(key, value) {
                this.imageLoader = true;

                this.config = {
                    ...this.config,
                    [key]: value
                };
            },
            imageLoaded() {
                this.imageLoader = false;
            }
        }
    }
</script>
