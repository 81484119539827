<template>
    <div class="configurator silent-opus-configurator">
        <div class="preview-images d-flex justify-center align-center">
            <div class="images-container d-flex align-start justify-center overflow-hidden">
                <img
                    v-for="(img, idx) in productImages"
                    :key="idx"
                    :src="img"
                    alt="">
                <canvas width="876" height="722" ref="canvas"></canvas>
            </div>
            <loader v-if="imageLoader"/>
        </div>
        <div :class="['preview-options d-flex', {'is-loading': imageLoader}]">
            <div class="stone-options">
                <stone-style
                    @onChange="value => updateConfig('stoneStyle', value)"/>
                <stone-shape
                    v-model="config.stones[config.stoneStyle].stoneShape"
                    :un-available-combinations="unAvailableCombinations[config.stoneStyle].stoneShape"
                    :builder="'silentOpus'"
                    @onChange="value => updateConfig('stoneShape', value)"/>
                <gemstone
                    v-model="config.stones[config.stoneStyle].gemstone"
                    :un-available-combinations="unAvailableCombinations[config.stoneStyle].gemstone"
                    :builder="'silentOpus'"
                    @onChange="value => updateConfig('gemstone', value)"/>
            </div>

            <div class="engraving-wrap">
                <h3>Engraving</h3>
                <engraving-font
                    @onChange="value => updateConfig('font', value)"/>

                <div class="engraving-text configurator-options">
                    <h4>Text <span>(20 Character Limit)</span></h4>
                    <v-textarea
                        v-model="config.text"
                        outlined
                        auto-grow
                        hide-details
                        no-resize
                        height="58"
                        :maxlength="20"
                        placeholder="Type Here|"
                        class="engraving-text-input"
                        @keyup="updateEngraving"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/api";
    import StoneStyle from "@/components/ui/configuratorElements/StoneStyle";
    import StoneShape from "@/components/ui/configuratorElements/StoneShape";
    import Gemstone from "@/components/ui/configuratorElements/Gemstone";
    import EngravingFont from "@/components/ui/configuratorElements/EngravingFont";
    import Loader from "@/components/ui/Loader";

    export default {
        name: "silent-opus-configurator",
        components: {
            StoneStyle,
            StoneShape,
            Gemstone,
            EngravingFont,
            Loader
        },
        data() {
            return {
                imageLoader: false,
                productImages: [],
                config: {
                    vendorModel: "SORGDUO",
                    stoneStyle: "style_1",
                    stones: {
                        style_1: {
                            stoneShape: "RD",
                            gemstone: "DMND"
                        },
                        style_2: {
                            stoneShape: "PC",
                            gemstone: "DMND"
                        }
                    },
                    metalType: "14KY",
                    size: "75",
                    font: "",
                    text: ""
                },
                unAvailableCombinations: {
                    style_1: {
                        stoneShape: [],
                        gemstone: []
                    },
                    style_2: {
                        stoneShape: [],
                        gemstone: []
                    }
                }
            }
        },
        created() {
            this.loadProduct();
        },
        methods: {
            async loadProduct() {
                this.imageLoader = true;

                const { vendorModel, stones, metalType, size } = this.config;
                const { style_1, style_2 } = stones;
                const sku = `${vendorModel}-${style_1.stoneShape}${style_1.gemstone}-${style_2.stoneShape}${style_2.gemstone}-${metalType}-${size}`;

                const product = await api.getSilentOpusProduct(sku);
                const images = product.data.data.request_config[0].images.v1;
                const attributes = product.data.data.attributes;
                const disabledAttributes = product.data.data.request_config[0].disabled_next_attributes;
                const attributeGroups = {
                    stoneShape_1: 4,
                    gemstone_1: 3,
                    stoneShape_2: 5,
                    gemstone_2: 9
                    // taken from product.data.data.attribute_groups
                };

                this.clearImages();

                Object.keys(images).sort().forEach(key => {
                    this.productImages.push(images[key].x1);
                });

                attributes.forEach(attr => {
                    if (disabledAttributes.includes(attr.id)) {
                        switch (attr.attribute_group) {
                            case attributeGroups.stoneShape_1:
                                this.unAvailableCombinations.style_1.stoneShape.push(attr.value);
                                break;
                            case attributeGroups.gemstone_1:
                                this.unAvailableCombinations.style_1.gemstone.push(attr.value);
                                break;
                            case attributeGroups.stoneShape_2:
                                this.unAvailableCombinations.style_2.stoneShape.push(attr.value);
                                break;
                            case attributeGroups.gemstone_2:
                                this.unAvailableCombinations.style_1.gemstone.push(attr.value);
                                break;
                        }
                    }
                });

                this.imageLoader = false;
            },
            updateConfig(key, value) {
                if (key === "stoneShape" || key === "gemstone") {
                    this.config.stones[this.config.stoneStyle][key] = value
                } else {
                    this.config = {
                        ...this.config,
                        [key]: value
                    };
                }

                if (key === "stoneStyle") return;

                if (key === "font" || key === "text") {
                    this.updateEngraving();
                } else {
                    this.loadProduct();
                }
            },
            updateEngraving() {
                // copy-pasted from Silent Opus Project
                const drawTextAlongArc = (context, str, centerX, centerY, radius, angle, start, invert) => {
                    if (!str) return
                    if (str.length % 2 === 0) {
                        str = str + " ";
                    }
                    let len = str.length, s;
                    context.font = `20px ${this.config.font}`;
                    context.textAlign = "center";
                    context.save();
                    context.translate(centerX, centerY);
                    context.rotate(-1 * start);
                    context.rotate((invert ? 1 : -1) * (angle * len) / 2);
                    for (let n = 0; n < len; n++) {
                        context.rotate((invert ? -1 : 1) * angle);
                        context.save();
                        context.translate(0, (invert ? 1 : -1) * radius);
                        s = str[n];
                        context.fillStyle = "#000";
                        context.fillText(s, 0, 0);
                        context.restore();
                    }
                    context.restore();
                }

                const canvas = this.$refs.canvas;
                let context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);

                let centerX0 = canvas.width / 2,
                    centerY0 = (canvas.height) + 1,
                    angle0 = 0.032,
                    radius0 = 440,
                    start0 = 0.0

                drawTextAlongArc(context, this.config.text, centerX0, centerY0, radius0, angle0, start0);
            },
            clearImages() {
                this.productImages = [];
                this.unAvailableCombinations = {
                    style_1: {
                        stoneShape: [],
                        gemstone: []
                    },
                    style_2: {
                        stoneShape: [],
                        gemstone: []
                    }
                }
            }
        }
    }
</script>
