<template>
    <div class="letter-type configurator-options">
        <h4>Letter Type</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="letterType"
                mandatory
                class="d-flex"
                @change="$emit('onChange', letterType)">
                <template v-for="(type, idx) in letterTypes">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="type.value"
                        class="configurator-option pa-0"
                        :ripple="false"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <img :src="type.image" :alt="type.label">
                                    <img class="configurator-option__checked-icon" src="@/assets/icons/ic-checked.svg" alt="">
                                </div>
                                <p class="mb-0">{{ type.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "letter-type",
        data() {
            return {
                letterType: "UC",
                letterTypes: [
                    { value: "UC", label: "Uppercase", image: "https://t6q7c3m8.stackpathcdn.com/zbuilder/necklace/TYPF1011/TY1011-UC-WG-A-V1.png" },
                    { value: "LC", label: "Lowercase", image: "https://t6q7c3m8.stackpathcdn.com/zbuilder/necklace/TYPF1011/TY1011-LC-WG-A-V1.png" }
                ]
            }
        }
    }
</script>
