<template>
    <div class="engraving-font configurator-options">
        <h4>Font</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="engravingFont"
                mandatory
                class="d-flex"
                @change="$emit('onChange', engravingFont)">
                <template v-for="(font, idx) in engravingFonts">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="font.value"
                        class="configurator-option pa-0"
                        :ripple="false"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <p :class="`mb-0 ${font.class}`">{{ font.label }}</p>
                                </div>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "engraving-font",
        data() {
            return {
                engravingFont: "Classic",
                engravingFonts: [
                    { value: "Futura PT", label: "Classic", class: "" },
                    { value: "Bodoni Moda", label: "Modern", class: "modern-font" },
                    { value: "Style Script", label: "Script", class: "script-font" }
                ]
            }
        }
    }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&display=swap');

.modern-font {
    font-family: 'Bodoni Moda', serif;
}

.script-font {
    font-family: 'Style Script', cursive;
}
</style>
