<template>
    <div class="stone-style configurator-options">
        <h4>Stone Selected</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="stoneStyle"
                mandatory
                class="d-flex"
                @change="$emit('onChange', stoneStyle)">
                <template v-for="(style, idx) in stoneStyles">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="style.value"
                        class="configurator-option pa-0"
                        :ripple="false"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <p class="mb-0">{{ style.label }}</p>
                                </div>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "stone-style",
        data() {
            return {
                stoneStyle: "style_1",
                stoneStyles: [
                    { value: "style_1", label: "Stone Style 1" },
                    { value: "style_2", label: "Stone Style 2" }
                ]
            }
        }
    }
</script>
