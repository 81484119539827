<template>
    <div class="configurator vera-wang-configurator">
        <div class="preview-images d-flex justify-center align-center">
            <img
                @load="imageLoaded"
                :class="{'is-scaled': config.centerSetting !== 'S1'}"
                :src="`https://t6q7c3m8.stackpathcdn.com/zbuilder/ring/regular/xcr30/XS${config.mountingStyle}+${config.stoneShape}+K+${config.centerSetting}+${config.metalColor}+SE${config.type}+VW1.gif`">
            <img
                @load="imageLoaded"
                :src="`https://t6q7c3m8.stackpathcdn.com/zbuilder/ring/regular/xcr30/XS${config.mountingStyle}+${config.metalColor}+SE${configExtraType}+VW1.gif`"
                style="z-index: -1">
            <img
                @load="imageLoaded"
                :class="{'is-scaled': config.centerSetting !== 'S1'}"
                :src="config.gemstone !== 'WD' ?
                    `https://t6q7c3m8.stackpathcdn.com/zbuilder/ring/regular/xcr30/XS${config.mountingStyle}+${config.stoneShape}+K+${config.centerSetting}+SE${configExtraType}+${config.gemstone}+VW1.png` :
                    `https://t6q7c3m8.stackpathcdn.com/zbuilder/ring/regular/xcr30/XS${config.mountingStyle}+${config.stoneShape}+K+${config.centerSetting}+${config.metalColor}+SE${config.type}+VW1.gif`
                "
                style="z-index: 0">
            <loader v-if="imageLoader"/>
        </div>
        <div class="preview-options d-flex">
            <stone-shape
                :config="config"
                :un-available-combinations="unAvailableCombinations"
                @onChange="value => updateConfig('stoneShape', value)"/>
            <center-setting
                :config="config"
                :un-available-combinations="unAvailableCombinations"
                @onChange="value => updateConfig('centerSetting', value)"/>
            <gemstone
                v-model="config.gemstone"
                :config="config"
                :un-available-combinations="unAvailableCombinations"
                @onChange="value => updateConfig('gemstone', value)"/>
            <mounting-style
                :config="config"
                :un-available-combinations="unAvailableCombinations"
                @onChange="value => updateConfig('mountingStyle', value)"/>
            <metal-color
                @onChange="value => updateConfig('metalColor', value)"/>
        </div>
    </div>
</template>

<script>
    import StoneShape from "@/components/ui/configuratorElements/StoneShape";
    import CenterSetting from "@/components/ui/configuratorElements/CenterSetting";
    import Gemstone from "@/components/ui/configuratorElements/Gemstone";
    import MountingStyle from "@/components/ui/configuratorElements/MountingStyle";
    import MetalColor from "@/components/ui/configuratorElements/MetalColor";
    import Loader from "@/components/ui/Loader";

    export default {
        name: "vera-wang-configurator",
        components: {
            StoneShape,
            CenterSetting,
            MountingStyle,
            Gemstone,
            MetalColor,
            Loader
        },
        data() {
            return {
                imageLoader: true,
                config: {
                    stoneShape: "RD",
                    gemstone: "WD",
                    centerSetting: "S1",
                    mountingStyle: "1",
                    metalColor: "W",
                    type: "C4"
                },
                unAvailableCombinations: [
                    {
                        stoneShapes: ["CU", "PE", "OV", "EM"],
                        centerSettings: ["H3", "H4"],
                        mountingStyles: ["1", "4", "9", "10", "11"],
                        gemstones: []
                    },
                    {
                        stoneShapes: ["CU"],
                        centerSettings: ["H1", "H2"],
                        mountingStyles: ["4"],
                        gemstones: []
                    },
                    {
                        stoneShapes: ["CU", "PE"],
                        centerSettings: ["S1", "H1", "H2", "H3", "H4"],
                        mountingStyles: [],
                        gemstones: ["RGA", "EMR", "BSP", "BLK"]
                    }
                ]
            }
        },
        computed: {
            configExtraType() {
                if (this.config.centerSetting === "H2" && this.config.stoneShape === "OV" && ["RGA", "EMR", "BSP", "BLK"].includes(this.config.gemstone)) {
                    return "C2";
                }
                return this.config.type;
            }
        },
        methods: {
            updateConfig(key, value) {
                this.imageLoader = true;

                this.config = {
                    ...this.config,
                    [key]: value
                };

                this.setType();
            },
            imageLoaded() {
                this.imageLoader = false;
            },
            setType() {
                if (
                    ["S1", "H1", "H2"].includes(this.config.centerSetting) && ["OV", "EM"].includes(this.config.stoneShape) ||
                    this.config.centerSetting === "S1" && ["11", "10", "4"].includes(this.config.mountingStyle) && ["PE"].includes(this.config.stoneShape) ||
                    ["H1", "H2"].includes(this.config.centerSetting) && ["11"].includes(this.config.mountingStyle) && ["PE"].includes(this.config.stoneShape)
                ) {
                    this.config.type = "C3"
                    return;
                }

                if (["H1", "H2"].includes(this.config.centerSetting) && ["1", "10", "9"].includes(this.config.mountingStyle) && ["RD"].includes(this.config.stoneShape)) {
                    this.config.type = "C6"
                    return;
                }

                if (
                    ["H1", "H2"].includes(this.config.centerSetting) && ["1", "10", "9"].includes(this.config.mountingStyle) && ["CU"].includes(this.config.stoneShape) ||
                    ["H3", "H4"].includes(this.config.centerSetting) && ["1", "10", "9"].includes(this.config.mountingStyle) && ["RD"].includes(this.config.stoneShape)
                ) {
                    this.config.type = "C7"
                    return;
                }

                if (["H1", "H2"].includes(this.config.centerSetting) && ["11", "4"].includes(this.config.mountingStyle) && ["RD", "PE"].includes(this.config.stoneShape)) {
                    this.config.type = "C5"
                    return;
                }

                if (
                    ["H1", "H2"].includes(this.config.centerSetting) && ["11"].includes(this.config.mountingStyle) && ["CU"].includes(this.config.stoneShape) ||
                    ["H3", "H4"].includes(this.config.centerSetting) &&  ["11", "4"].includes(this.config.mountingStyle) && ["RD"].includes(this.config.stoneShape)
                ) {
                    this.config.type = "C2"
                    return;
                }

                this.config.type = "C4"
            }
        }
    }
</script>
