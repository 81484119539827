<template>
    <div class="letter configurator-options">
        <h4>Select Letter</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="letter"
                mandatory
                class="d-flex"
                @change="$emit('onChange', letter.toUpperCase())">
                <template v-for="(char, idx) in chars[config.letterType]">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="char"
                        class="configurator-option pa-0"
                        :ripple="false"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <p class="mb-0">{{ char }}</p>
                                </div>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "letter",
        props: {
            config: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                letter: "A",
                chars: {
                    UC: [],
                    LC: []
                }
            }
        },
        created() {
            this.addLetter();
            this.addLetter("LC");
        },
        methods: {
            addLetter(type = "UC") {
                const start = type === "LC" ? 97 : 65;
                const end = type === "LC" ? 123 : 91;
                for (let i = start; i < end; ++i) {
                    this.chars[type].push(String.fromCharCode(i));
                }
            }
        }
    }
</script>
