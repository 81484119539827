<template>
    <div class="configurator-page">
        <section id="header">
            <div class="info-block">
                <div class="title-wrapper">
                    <h1>Infinite combinations</h1>
                </div>
                <div class="separation-line"/>
                <h5>
                    Customize your visualizations—down to the tiniest detail. Cheers for the freedom to choose.
                </h5>
                <!--                <button>Try it for Free</button>-->
            </div>
            <div class="background">
                <div class="left-circles">
                    <img
                        src="@/assets/ConfiguratorPageImages/left-circles-png.png">
                </div>
                <div class="right-circles">
                    <img
                        src="@/assets/ConfiguratorPageImages/right-circles-png.png">
                </div>
            </div>
            <div class="mobile-version-wrapper">
                <h1>Infinite combinations</h1>
                <div class="separation-line"/>
                <p>
                    Customize your visualizations—down to the tiniest detail. Cheers for the freedom to choose.
                </p>
                <!--                <button>Try it for Free</button>-->
            </div>
        </section>
        <section id="dynamic">
            <div v-if="$vuetify.breakpoint.smAndUp" class="content-wrapper home-row">
                <div class="preview-block">
                    <div class="image-wrapper">
                        <video loop muted playsinline preload="auto">
                            <source :src="require(`@/assets/video/dynamic-is-better-v2.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/dynamic-is-better-v2.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
                <div class="info-block home-row">
                    <div class="stats-column home-column">
                        <div class="stat-wrapper">
                            <div class="evidence">
                                50%
                            </div>
                            <div class="name">
                                <strong>Faster</strong> than a traditional photoshoot
                            </div>
                        </div>
                        <div class="stat-wrapper">
                            <div class="evidence">
                                100%
                            </div>
                            <div class="name">
                                <strong>Adaptable</strong> to any use case or requirement
                            </div>
                        </div>
                    </div>
                    <div class="info-column">
                        <h6>Dynamic is Better</h6>
                        <div class="separation-line"/>
                        <p>
                            <strong>Why stress</strong> over lighting challenges, retouching, reshoots, and added production time when you can have a
                            <strong>powerful marketing tool</strong> at your fingertips?
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="$vuetify.breakpoint.xsOnly" class="mobile-version-wrapper">
                <div class="image-wrapper">
                    <video loop muted playsinline preload="auto">
                        <source :src="require(`@/assets/video/dynamic-is-better-v2.mp4`)" type="video/mp4">
                        <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/dynamic-is-better-v2.mp4`)">link to the video</a> instead.</p>
                    </video>
                </div>
                <div class="title-wrapper">
                    <h6>Dynamic is Better</h6>
                    <p>
                        <strong>Why stress</strong> over lighting challenges, retouching, reshoots, and added production time when you can have a
                        <strong>powerful marketing tool</strong> at your fingertips?
                    </p>
                </div>
                <div class="stats">
                    <div class="stat">
                        <div class="evidence">
                            50%
                        </div>
                        <div class="name">
                            <strong>Faster</strong> than a traditional photoshoot
                        </div>
                    </div>
                    <div class="stat">
                        <div class="evidence">
                            100%
                        </div>
                        <div class="name">
                            <strong>Adaptable</strong> to any use case or requirement
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="cutomizable-rendering">
            <div class="info-block">
                <h6>
                    Create remarkable experiences with fully customizable
                    rendering
                </h6>
                <div class="separation-line"/>
                <h5>
                    Take your digital product experiences to the next
                    level—without the hassle.
                </h5>
            </div>
            <div class="preview">
                <v-list class="configurators-list pa-0">
                    <v-list-item-group
                        v-model="configurator"
                        mandatory>
                        <v-list-item
                            :value="'taylorYou'"
                            class="pa-0 overflow-hidden"
                            :ripple="false"
                            dark>
                            <template v-slot:default>
                                <v-list-item-content class="pa-0">
                                    <img v-if="$vuetify.breakpoint.smAndUp" src="@/assets/ConfiguratorPageImages/taylor-you.png" alt="">
                                    <p class="mb-0"><strong>Fashion</strong></p>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                        <v-list-item
                            :value="'silentOpus'"
                            class="pa-0 overflow-hidden"
                            :ripple="false"
                            dark>
                            <template v-slot:default>
                                <v-list-item-content class="pa-0">
                                    <img v-if="$vuetify.breakpoint.smAndUp" src="@/assets/ConfiguratorPageImages/silent-opus.png" alt="">
                                    <p class="mb-0"><strong>Fine</strong></p>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                        <v-list-item
                            :value="'veraWang'"
                            class="pa-0 overflow-hidden"
                            :ripple="false"
                            dark>
                            <template v-slot:default>
                                <v-list-item-content class="pa-0">
                                    <img v-if="$vuetify.breakpoint.smAndUp" src="@/assets/ConfiguratorPageImages/vera-wang.png" alt="">
                                    <p class="mb-0"><strong>Bridal</strong></p>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <taylor-you-configurator v-show="configurator === 'taylorYou'"/>

                <silent-opus-configurator v-show="configurator === 'silentOpus'"/>

                <vera-wang-configurator v-show="configurator === 'veraWang'"/>
            </div>
        </section>
        <section
            id="customer-confidence"
            class="home-row">
            <div class="preview-block">
                <div class="image-wrapper">
                    <img src="@/assets/ConfiguratorPageImages/customer-confidence.png">
                </div>
            </div>
            <div class="info-block">
                <h6>Inspire customer confidence</h6>
                <div class="separation-line"/>
                <h5>Drive sales by providing a truly dynamic customer experience.</h5>
                <p>With unco, customers can view products in perfect 3D detail--from anywhere.</p>
            </div>
        </section>

        <getting-started-block/>
    </div>
</template>

<script>
    import GettingStartedBlock from "@/components/HomePageComponents/GettingStartedBlock";
    import TaylorYouConfigurator from "./TaylorYouConfigurator";
    import SilentOpusConfigurator from "./SilentOpusConfigurator";
    import VeraWangConfigurator from "./VeraWangConfigurator";

    export default {
        name: "page-configurator",
        components: {
            "getting-started-block": GettingStartedBlock,
            TaylorYouConfigurator,
            SilentOpusConfigurator,
            VeraWangConfigurator
        },
        data: () => ({
            modelWidth: null,
            modelHeight: null,
            observer: null,
            configurator: "taylorYou"
        }),
        mounted() {
            this.observer = new IntersectionObserver(this.onElementObserved, {
                threshold: 1
            });

            document.querySelectorAll("video").forEach(lazyVideo => {
                this.observer.observe(lazyVideo);
            });

            this.$nextTick(() => {
                this.getModelSize();
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
        methods: {
            onElementObserved(entries) {
                entries.forEach(({ target, isIntersecting }) => {
                    if (!isIntersecting) {
                        return;
                    }

                    target.play();
                    this.observer.unobserve(target);
                });
            },
            getModelSize() {
                const modelWrapper = document.getElementById("viewer-wrapper");

                if (modelWrapper) {
                    this.modelWidth = modelWrapper.getBoundingClientRect().width;
                    this.modelHeight =
                        modelWrapper.getBoundingClientRect().height - 30;
                } else {
                    this.modelWidth = 100;
                    this.modelHeight = 100;
                }
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
<style lang="scss" src="@/components/ui/configuratorElements/style.scss"></style>
