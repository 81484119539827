<template>
    <div class="stone-type configurator-options">
        <h4>Gemstone</h4>
        <v-list class="configurator-options-list pa-0">
            <v-list-item-group
                v-model="gemstone"
                mandatory
                class="d-flex">
                <template v-for="(gemstone, idx) in gemstones[builder]">
                    <v-list-item
                        :key="`item-${idx}`"
                        :value="gemstone.value"
                        :disabled="isDisabled(gemstone)"
                        :ripple="false"
                        class="configurator-option pa-0"
                        dark>
                        <template v-slot:default>
                            <v-list-item-content class="pa-0">
                                <div class="configurator-option__img-wrap">
                                    <img :src="gemstone.image" :alt="gemstone.label">
                                </div>
                                <p class="mb-0">{{ gemstone.label }}</p>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "gemstone",
        props: {
            value: {
                type: String,
                default: "WD"
            },
            builder: {
                type: String,
                default: "veraWang" // veraWang, taylorYou, silentOpus
            },
            config: {
                type: Object,
                default: null
            },
            unAvailableCombinations: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                gemstones: {
                    veraWang: [
                        {
                            value: "WD",
                            label: "White Diamond",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/d.jpg"
                        },
                        {
                            value: "RGA",
                            label: "Ruby",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/RGA.png"
                        },
                        {
                            value: "EMR",
                            label: "Emerald",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/EMR.png"
                        },
                        {
                            value: "BSP",
                            label: "Sapphire",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/BSP.png"
                        },
                        {
                            value: "BLK",
                            label: "Black Diamond",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/blk.jpg"
                        }
                    ],
                    silentOpus: [
                        {
                            value: "DMND",
                            label: "White Diamond",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/d.jpg"
                        },
                        {
                            value: "RUBY",
                            label: "Ruby",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/RGA.png"
                        },
                        {
                            value: "EMRD",
                            label: "Emerald",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/EMR.png"
                        },
                        {
                            value: "SPHR",
                            label: "Sapphire",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/BSP.png"
                        },
                        {
                            value: "BD",
                            label: "Black Diamond",
                            image: "https://t6q7c3m8.stackpathcdn.com/images/builder/blk.jpg"
                        }
                    ]
                }
            }
        },
        computed: {
            gemstone: {
                get() {
                    return this.$props.value;
                },
                set(nv) {
                    this.$emit("onChange", nv);
                }
            }
        },
        methods: {
            isDisabled(gemstone) {
                if (this.builder === "veraWang") {
                    const combinations = this.unAvailableCombinations.filter(comb => comb.gemstones.includes(gemstone.value));
                    return combinations.some(comb => {
                        return (comb.stoneShapes.length ? comb.stoneShapes.includes(this.config.stoneShape) : true) &&
                            (comb.centerSettings.length ? comb.centerSettings.includes(this.config.centerSetting) : true) &&
                            (comb.mountingStyles.length ? comb.mountingStyles.includes(this.config.mountingStyle) : true)
                    });
                }

                if (this.builder === "silentOpus") {
                    return this.unAvailableCombinations.includes(gemstone.value);
                }

                return false;
            }
        }
    }
</script>
